<template>
  <router-link
    tag="a"
    class="btn btn-inverse"
    :to="{ path: urlPath, name: routeName, params: routeParams, query: queryParams }"
    :title="$t('buttons.close')"
    @click.native="$emit('click')"
  >
    <i class="fas fa-window-close"></i> <span class="hidden-sm-down">{{ $t('buttons.close') }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'ButtonClose',
  props: {
    urlPath: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
      default: ''
    },
    routeParams: {
      type: String,
      default: ''
    },
    queryParams: {
      type: Object,
      default: null
    }
  }
}
</script>

<template>
  <div>
    <button
      type="button"
      class="btn-article-advanced-filter"
      :class="{
        'btn-article-advanced-filter--color-enabled': colorEnabled && isEnabled,
        'btn-article-advanced-filter--small': small
      }"
      data-test="article_btn_show_advanced_filter"
      @click="onClick"
    >
      <span v-if="label" class="btn-article-advanced-filter__label">
        {{ label }}
      </span>
      <SvgIconArrowUp
        class="btn-article-advanced-filter__arrow"
        :class="{ 'btn-article-advanced-filter__arrow--down': isEnabled }"
      />
    </button>
  </div>
</template>

<script>
import SvgIconArrowUp from '@/assets/img/svg/arrow-up.svg?inline'

export default {
  name: 'BtnArticleShowAdvancedFilter',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    colorEnabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SvgIconArrowUp
  }
}
</script>

<style scoped lang="scss">
  .btn-article-advanced-filter {
    min-width: rem(40px);
    height: rem(40px);
    border-radius: rem(6px);
    cursor: pointer;
    display: flex;
    color: #465674;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #d1dbe4;
    transition: all 100ms;
    &--small {
      height: rem(30px);
    }
    &:hover {
      border: solid 1px #6599fe;
      color: #6599fe;
      .btn-article-advanced-filter__arrow {
        fill: #6599fe;
      }
    }
    &--color-enabled {
      background: #6599FE;
      color: #FFFFFF;
      &:hover {
        background: darken(#6599FE, 10%);
        color: #FFFFFF;
        .btn-article-advanced-filter__arrow {
          fill: #FFFFFF;
        }
      }
    }
    &__arrow {
      width: rem(13px);
      height: rem(13px);
      fill: #9DAAC4;
      transition: all 400ms;
      transform: rotate(180deg);
      &--down {
        transform: rotate(0);
      }
    }
    &--color-enabled &__arrow {
      &--down {
        fill: #FFFFFF;
      }
    }
    &__label {
      @include font(700 13px "Roboto");
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
</style>

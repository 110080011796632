<template>
    <span v-if="!vlm">
        <button
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          ref="mediaUrlButton"
          @click="showMediaUrlModal"
        >
            <i :class="fontAwesomeIcon"></i>
            {{ btnText }}
        </button>
        <MediaUrlModal
          v-if="mediaUrlModal"
          @close="closeMediaUrlModal"
          @upload-finished="showMediaEditModal"
          @get-raw-url="getRawUrl"
          :type-of-modal="typeOfBtn"
          :media-url-modal-label-desc="mediaUrlModalLabelDesc"
        />
        <MediaEditModal
          ref="editModal"
          v-if="mediaEditModal"
          :selected-media="selectedMedia"
          :files-were-uploaded="true"
          @close="closeMediaEditModal"
          @add-media="addMedia"
          :isGallery="isGallery"
          :site="site"
          :media-usage-type="mediaUsageType"
          :allow-gallery-creation="allowGalleryCreation"
          :default-input-value-of-author="rawUrl"
        />
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import MediaUrlModal from './MediaUrlModal'
import MediaEditModal from '@/components/shared/MediaEditModal'
import MediaService from '@/services/media/MediaService'

export default {
  name: 'MediaUrlButton',
  props: {
    btnText: {
      type: String,
      required: false,
      default: function () {
        return this.$t('buttons.upload_from_url')
      }
    },
    files: {
      type: String
    },
    fontAwesomeIcon: {
      type: String,
      required: false,
      default: 'fas fa-link'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    mediaUrlModalLabelDesc: {
      type: String,
      required: false
    },
    typeOfBtn: {
      type: String, // url or embed
      required: false,
      default: 'url'
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    },
    allowGalleryCreation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mediaUrlModal: false,
      mediaEditModal: false,
      selectedMedia: [],
      rawUrl: ''
    }
  },
  components: {
    MediaUrlModal,
    MediaEditModal
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  methods: {
    showMediaUrlModal () {
      this.mediaUrlModal = true
      this.mediaEditModal = false
    },
    closeMediaUrlModal () {
      this.mediaUrlModal = false
    },
    getRawUrl (data) {
      this.rawUrl = data.toString()
    },
    showMediaEditModal (files) {
      this.selectedMedia = files.map(MediaService.transformUploadedFileToSelectedMedia)
      this.mediaEditModal = true
      this.$nextTick(() => {
        this.mediaUrlModal = false
      })
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    addMedia (media) {
      this.$emit('set-media', media)
    }
  }
}
</script>

<template>
    <span>
        <button
          class="btn-media-upload"
          :class="{ 'hide': hideButton }"
          id="mediaUploadButton"
          data-test="upload_image_btn"
          @click="openFileDialog()"
        >
            <slot>
              <i class="fas fa-upload"></i>
              {{ buttonText }}
            </slot>
        </button>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="readSelectedFiles"
          :multiple="multiple"
          accept="image/png, image/jpeg"
        >
        <MediaEditModal
          ref="mediaEditModal"
          v-if="mediaEditModal"
          :selected-media="selectedMedia"
          :showPageBreaksCheckbox="showPageBreaksCheckbox"
          @add-media="setMedia"
          @close="closeMediaEditModal"
          @add-page-breaks="addPageBreaks"
          :isGallery="isGallery"
          :site="site"
          :media-usage-type="mediaUsageType"
          :allow-gallery-creation="allowGalleryCreation"
          @set-gallery-group="$emit('set-gallery-group', $event)"
          :insertButtonLabel="insertButtonLabel"
        />
        <DamUploadModal
          ref="uploadModal"
          v-if="uploadModal"
          :files="files"
          :upload-source="uploadSource"
          @close="closeUploadModal"
          @set-media="afterUploadModalConfirmed"
        />
    </span>
</template>

<script>
import { SOURCE_EAGLE_IMAGE } from '@/model/ValueObject/DamUploadSources'
import MediaEditModal from '@/components/shared/MediaEditModal'
import DamUploadModal from '@/components/dam/damUploadModal/DamUploadModal'
import MediaService from '@/services/media/MediaService'

export default {
  name: 'MediaUploadButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: function () {
        return this.$t('buttons.upload_from_computer')
      }
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    },
    allowGalleryCreation: {
      type: Boolean,
      default: false
    },
    insertButtonLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      files: [],
      uploadModal: false,
      mediaEditModal: false,
      selectedMedia: [],
      uploadSource: SOURCE_EAGLE_IMAGE
    }
  },
  components: {
    MediaEditModal,
    DamUploadModal
  },
  methods: {
    openFileDialog () {
      // Clear input file value, prevent to open same file
      this.$refs.fileInput.value = ''
      this.$refs.fileInput.click()
    },
    async readSelectedFiles (event) {
      this.files = Object.values(event.target.files)
      this.showUploadModal()
    },
    showUploadModal () {
      this.uploadModal = true
    },
    closeUploadModal () {
      this.uploadModal = false
    },
    afterUploadModalConfirmed (savedDamAssets) {
      this.selectedMedia = savedDamAssets.map(MediaService.transformUploadedFileToSelectedMedia)
      this.showMediaEditModal()
    },
    showMediaEditModal () {
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    addPageBreaks (pageBreaks) {
      this.$emit('add-page-breaks', pageBreaks)
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-media-upload {
    @include font(500 13px "Roboto");
    @include padding(7px 14px);
    @include radius(4px);
    cursor: pointer;
    // display: inline-block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    @include bp(10) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
  }
</style>

<template>
  <div :class="{'form-group': !disableFormGroup, 'has-danger': error}">
    <label :for="id" v-if="!noLabel">
      {{ label }} <span v-if="required" class="required">*</span>
    </label>
    <div class="input-group">
      <app-color-picker v-model="color"></app-color-picker>
      <input
        type="text"
        :id="id"
        class="form-control"
        @input="$emit('input', validate($event.target.value))"
        @blur="$emit('blur', validate($event.target.value))"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-test="dataAttr ? dataAttr : id"
      >
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'ColorPicker',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      color: ''
    }
  },
  watch: {
    color (data) {
      if (data.rgba) {
        const cssColor = 'rgba(' + data.rgba.r + ',' + data.rgba.g + ',' + data.rgba.b + ',' + data.rgba.a + ')'
        this.$emit('input', cssColor)
      }
    }
  },
  components: {
    appColorPicker: Chrome
  },
  methods: {
    validate (value) {
      if (this.max) {
        return this.validateMax(value)
      }
      return value
    },
    validateMax (value) {
      if (value > this.max) {
        return this.max
      }
      return value
    },
    initColor () {
      let rgba = this.value.replace('rgba(', '')
      rgba = rgba.replace(')', '')
      rgba = rgba.split(',')

      if (rgba.length === 4) {
        this.color = {
          r: rgba[0],
          g: rgba[1],
          b: rgba[2],
          a: rgba[3]
        }
      }
    }
  },
  created () {
    this.initColor()
  },
  mounted () {
    window.$('.colorpicker').asColorPicker()
  }
}
</script>

<style lang="scss">
  .input-group-text {
    width: 50px;
    font-size: 12px;
    text-align: center;
  }
  .has-danger {
    .input-group-text {
      color: #fc4b6c;
    }
  }
  .asColorPicker-trigger {
    border: 1px solid #cccccc;
  }
</style>

<template>
    <span>
        <span
          v-if="video.status === 'new'"
          class="label label-inverse text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_new') }}</strong>
        </span>
        <span
          v-if="video.status === 'uploading'"
          class="label label-info text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_uploading') }}</strong>
        </span>
        <span
          v-else-if="video.status === 'processing'"
          class="label btn-ready text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_processing') }}</strong>
        </span>
        <span
          v-else-if="video.status === 'live'"
          class="label btn-green text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_live') }}</strong>
        </span>
        <span
          v-else-if="video.status === 'published'"
          class="label btn-green text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_published') }}</strong>
        </span>
        <span
          v-else-if="video.status === 'error'"
          class="label btn-danger text-uppercase"
          data-test="article_status"
        >
            <strong>{{ $t('video.status_error') }}</strong>
        </span>
    </span>
</template>

<script>
export default {
  props: {
    video: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.btn-ready {
  background: #ffb22b;
}
</style>

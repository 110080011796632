export const VIDEO_TYPE_OOYALA = 'ooyala'
export const VIDEO_TYPE_YOUTUBE = 'youtube'
export const VIDEO_TYPE_LIVEBOX = 'livebox'

export const VIDEO_TYPE_DEFAULT = VIDEO_TYPE_LIVEBOX

export default {
  computed: {
    videoTypeValues () {
      return [
        {
          id: VIDEO_TYPE_OOYALA,
          title: this.$t('video.video_type_value.ooyala')
        },
        {
          id: VIDEO_TYPE_YOUTUBE,
          title: this.$t('video.video_type_value.youtube')
        },
        {
          id: VIDEO_TYPE_LIVEBOX,
          title: this.$t('video.video_type_value.livebox')
        }
      ]
    }
  }
}

<template>
    <span>
      <i
        class="fab fa-youtube"
        v-if="video.type === 'youtube'"
        title="Youtube"
      />
      <i
        class="fas fa-video"
        v-if=" ['livebox', 'ooyala'].includes(video.type)"
        :title="video.type === 'livebox' ? 'Livebox' : 'Ooyala'"
      />
    </span>
</template>

<script>
export default {
  name: 'VideoTypeIcon',
  props: {
    video: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-youtube {
    color: #ff0000;
  }
</style>

<template>
  <section>
    <div class="row ">
      <div class="col-lg-12">
        <span>{{ $t('video.range_slider_info_1') }}: </span>
        <strong>
          {{ annotationRange[0] | formatMilliseconds }} -
          {{ annotationRange[1] | formatMilliseconds }}
        </strong>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 px-4">
        <VueSlider
          v-model="annotationRange"
          :max="video.videoDuration"
          dotSize="20"
          tooltip="none"
          @change="updateAnnotationRange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import VideoAnnotationModel from '../../model/VideoAnnotation'

export default {
  name: 'VideoAnnotationRangeSlider',
  props: {
    video: {
      type: Object
    },
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      annotationRange: [
        this.start,
        this.end
      ],
      videoAnnotation: VideoAnnotationModel
    }
  },
  components: {
    VueSlider
  },
  methods: {
    updateAnnotationRange (range) {
      this.annotationRange = range
      this.videoAnnotation.start = range[0]
      this.videoAnnotation.end = range[1]
      this.$emit('change', range)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

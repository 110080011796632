<template>
  <div style="text-align: right">
    <button
      class="btn btn-xs btn-info m-b-5"
      :title="$t('article.create_new_tag')"
      v-if="searchedTag !== ''"
      @click="createTag"
    >
      <i class="fa fa-plus"></i>
      {{ $t('article.create_new_tag') }} <strong>{{ searchedTag }}</strong>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TagModel from '@/model/Tag'
import { TagTypes } from '@/model/ValueObject/TagTypes'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'CreateTagButton',
  props: {
    searchedTag: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tag: this._.cloneDeep(TagModel)
    }
  },
  computed: {
    ...mapGetters({
      currentTagError: 'tag/error'
    }),
    TagTypes () {
      return TagTypes
    }
  },
  methods: {
    ...mapActions('tag', ['create']),

    async createTag () {
      await this.validateTagFields()

      this.create(this.tag)
        .then((response) => {
          if (this.currentTagError === null) {
            NotifyService.setSuccessMessage(this.$t('article.create_new_tag_message'))
            this.$emit('set-tag', response)
          } else {
            NotifyService.setErrorMessage(this.currentTagError)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    async validateTagFields () {
      if (this.tag.title === '') {
        this.tag.title = this.searchedTag
      }
      if (!this.tag.description) {
        this.tag.description = ''
      }
      if (!this.tag.type) {
        this.tag.type = this.TagTypes.GENERAL
      }
      if (!this.tag.slug || this.tag.slug === '') {
        this.tag.slug = this.generateSlug(this.tag.title)
      }
    },
    generateSlug (title) {
      return title.trim().toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '')
    }
  },
  watch: {
    searchedTag (newValue) {
      this.tag.title = newValue
      this.tag.slug = this.generateSlug(newValue)
    }
  }
}
</script>

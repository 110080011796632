import { mapGetters } from 'vuex'

const CROPPER_WIDTH = 1000

export default {
  data () {
    return {
      dataLoaded: false,
      croppedImage: '',
      cropperWidth: CROPPER_WIDTH
    }
  },
  computed: {
    ...mapGetters('dam', [
      'cropPositionEmbed'
    ])
  },
  methods: {
    // FACE DETECT
    faceDetect () {
      const tracker = new window.tracking.ObjectTracker(['face'])
      tracker.setStepSize(1.6)
      window.tracking.track('.cropper-view-box img', tracker)
      tracker.on('track', this.track)
    },
    track (event) {
      event.data.forEach(this.plot)
      if (event.data.length > 0) {
        this.changeCrop(event.data)
      }
    },
    plot (face) {
      const img = document.querySelector('.cropper-view-box img')
      const offsetX = (this.cropperWidth - img.width) / 2
      var rect = document.createElement('div')
      document.querySelector('.cropper-container').appendChild(rect)
      rect.classList.add('rect')
      rect.style.width = face.width + 'px'
      rect.style.height = face.height + 'px'
      rect.style.left = (img.offsetLeft + face.x + offsetX) + 'px'
      rect.style.top = (img.offsetTop + face.y) + 'px'
    },
    changeCrop (data) {
      let minY = 600
      let maxY = 0
      const imageCanvasHeight = 600
      let yCord = 0

      data.forEach((rect) => {
        if (minY > rect.y) {
          minY = rect.y + (rect.height / 2)
        }
        if (maxY < rect.y) {
          maxY = rect.y + (rect.height / 2)
        }
      })

      const yCenter = ((maxY + minY) / 2) / imageCanvasHeight
      if (yCenter > 0.3333) {
        yCord = yCenter - 0.3333
      }

      this.setCrop({
        upperLeftCoords: {
          xCoord: 0.00001,
          yCoord: yCord
        },
        lowerRightCoords: {
          xCoord: 1,
          yCoord: 1
        }
      })
    },
    setCropperWidth (width) {
      this.cropperWidth = width
    }
  }
}

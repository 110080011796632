<template>
  <div class="media-preview">
    <div class="row">
      <div :class="{'col-lg-6': showMediaInfo, 'col-lg-12': !showMediaInfo}">
        <img
          v-if="media.damMediaEmbed"
          :src="getDamImagePath(media)"
          :title="media.imageCaption"
          width="100%"
          height="auto"
        />
      </div>
      <div class="col-lg-6" v-if="showMediaInfo">
        <div class="media-info">
          <strong>{{ $t('media.author') }}: </strong>{{ media.imageAttribution }}<br>
          <strong>{{ $t('media.caption') }}: </strong>{{ media.imageCaption }}<br>
          <app-button-copy-to-clipboard
            data-test="media_damid__btn_copy"
            :label="$t('dam.copy_id')"
            :data="media.damMediaEmbed.damId"
            :notify-message="$t('notify.id_was_copied')"
          >
          </app-button-copy-to-clipboard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnCopyToClipboard from '../buttons/BtnCopyToClipboard'
import DamService from '@/services/dam/DamService'

export default {
  name: 'Media',
  props: {
    media: {
      type: Object
    },
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 202
    },
    showMediaInfo: {
      type: Boolean,
      default: false
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    }
  },
  data () {
    return {
      modal: ''
    }
  },
  components: {
    appButtonCopyToClipboard: BtnCopyToClipboard
  },
  methods: {
    getDamImagePath (media) {
      return DamService.getDamImagePathForMedia(media, this.mediaUsageType)
    }
  }
}
</script>

<style lang="scss">
.media-preview {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  display: block;

  img {
    float: left;
    margin-right: 10px;
  }
}
</style>

<template>
  <div
    class="progress"
    :class="{'reset': actualAnimateStep === 0 }"
  >
    <div
      class="progress-bar"
      :class="this.bgClass"
      role="progressbar"
      :style="'width: ' + currentPercentage + '%;height: ' + height + 'px;'"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Autoloader',
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 6
    },
    bgClass: {
      type: String,
      default: 'bg-success'
    },
    refreshOnStart: {
      type: Boolean,
      default: false
    },
    callback: Function,
    interval: {
      type: Number,
      default: 30
    }
  },
  data () {
    return {
      animateTimer: 0,
      actualAnimateStep: 0
    }
  },
  computed: {
    currentPercentage () {
      return Math.abs(Math.ceil(100 * this.actualAnimateStep / this.interval) - 100)
    }
  },
  watch: {
    enabled (newVal) {
      if (newVal) {
        if (this.refreshOnStart) {
          this.callback()
        }
        this.startCountdown()
      } else {
        this.stopCountdown()
      }
    }
  },
  methods: {
    startCountdown () {
      clearInterval(this.animateTimer)
      this.animateTimer = setInterval(this.animateStep, 1000)
    },
    refreshCountdown () {
      clearInterval(this.animateTimer)
      this.actualAnimateStep = 0
    },
    stopCountdown () {
      clearInterval(this.animateTimer)
    },
    animateStep () {
      if (this.actualAnimateStep === this.interval) {
        this.callback()
        this.actualAnimateStep = 0
      } else {
        this.actualAnimateStep += 1
      }
    }
  },
  mounted () {
    if (this.enabled) {
      if (this.refreshOnStart) {
        this.callback()
      }
      this.startCountdown()
    } else {
      this.stopCountdown()
    }
  },
  beforeDestroy () {
    clearInterval(this.animateTimer)
  }
}
</script>

<style scoped lang="scss">
  .progress {
    justify-content: center;
    background-color: transparent;

    &.reset {
      .progress-bar {
        transition: width 0.3s linear;
      }
    }
  }
  .progress-bar {
    transition: width 1s linear;
    background-color: #6599fe !important;
  }
</style>

<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="!dataLoaded"></app-preloader-full>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-6">
            <div class="m-t-10">
              <h3
                class="m-b-0"
                v-if="video.id"
              >
                {{ $t('video.annotationsFor') }} "{{ video.meta.title }}"
              </h3>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save('edit')"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="save('close')"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save_and_close') }}
              </button>
              <app-button-close route-name="video_edit_old" route-params="{ id: video.id }"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <app-video-annotation-range-slider
            v-if="dataLoaded"
            :video="video"
            :start="annotation.start"
            :end="annotation.end"
            @change="updateRange"
          >
          </app-video-annotation-range-slider>
          <app-video-annotation-gantt
            :video="video"
          >
          </app-video-annotation-gantt>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8 text-right">
              <label for="annotation_type" class="m-r-10">{{ $t('video.annotation_type') }}</label>
              <label
                v-for="(annotationType, index) in videoAnnotationTypes"
                :key="`item-${index}`"
                class="btn btn-secondary m-r-5"
                :class="{ 'active': annotation.type == annotationType.id }"
              >
                <input
                  id="annotation_type"
                  type="radio"
                  v-model="annotation.type"
                  :value="annotationType.id"
                  :data-test="'type_' + annotationType.id"
                >
                {{ annotationType.title }}
              </label>
            </div>
            <div class="col-8">
              <div
                v-if="annotation.type === 'video'"
                class="row"
              >
                <div class="col-lg-6">
                  <app-input
                    v-model.trim="annotation.annotationVideoId"
                    @blur="$v.annotation.annotationVideoId.$touch()"
                    :error="$v.annotation.annotationVideoId.$error"
                    id="annotation_videoId"
                    :label="$t('video.annotation_video_id')"
                    disabled
                  >
                  </app-input>
                </div>
                <div class="col-lg-6">
                  <button class="btn btn-info m-t-30"
                          @click="showVideoListModal"
                  >
                    <i class="fas fa-video"></i> {{ $t('buttons.select_video') }}
                  </button>
                </div>
              </div>
              <app-video-list-modal
                v-if="videoListModal"
                @set-data="setVideo"
                @close="closeVideoListModal"
              >

              </app-video-list-modal>
              <app-input
                v-model.trim="annotation.title"
                @blur="$v.annotation.title.$touch()"
                :error="$v.annotation.title.$error"
                id="annotation_title"
                :label="$t('video.annotation_title')"
                :maxlength="40"
                show-counter
              >
              </app-input>
              <app-input
                v-if="annotation.type !== 'video'"
                v-model.trim="annotation.url"
                @blur="$v.annotation.url.$touch()"
                :error="$v.annotation.url.$error"
                id="annotation_url"
                :label="$t('video.annotation_url')"
              >
              </app-input>
              <app-textarea
                v-if="annotation.type === 'article'"
                v-model="annotation.perex"
                @blur="$v.annotation.perex.$touch()"
                :error="$v.annotation.perex.$error"
                id="annotation_perex"
                :label="$t('video.annotation_perex ')"
                :rows=2
                :maxlength="60"
                show-counter
              >
              </app-textarea>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ $t('video.annotation_image') }}</label><br>
                    <app-media-select-button @set-media="setImage"></app-media-select-button>
                    <app-media-upload-button @set-media="setImage"></app-media-upload-button>
                    <app-media-url-button @set-media="setImage"></app-media-url-button>
                    <app-media-editable
                      v-if="annotation.image"
                      :media="annotation.expanded.image"
                      :width="360"
                      :height="202"
                      @set-media="setImage"
                      @remove-media="removeImage"
                      class="m-t-10"
                    >
                    </app-media-editable>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ $t('video.annotation_image_mobile') }}</label><br>
                    <app-media-select-button @set-media="setImageMobile"></app-media-select-button>
                    <app-media-upload-button @set-media="setImageMobile"></app-media-upload-button>
                    <app-media-url-button @set-media="setImageMobile"></app-media-url-button>
                    <app-media-editable
                      v-if="annotation.imageMobile"
                      :media="annotation.expanded.imageMobile"
                      :width="360"
                      :height="202"
                      @remove-media="removeImageMobile"
                      class="m-t-10"
                    >
                    </app-media-editable>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <app-input
                v-model="annotation.border"
                id="annotation_border"
                :label="$t('video.annotation_border')"
              >
              </app-input>
              <div class="row">
                <div class="col-lg-6">
                  <app-color-picker
                    v-if="dataLoaded"
                    v-model.trim="annotation.bgColor"
                    id="annotation_bg_color"
                    :label="$t('video.annotation_bg_color')"
                  >
                  </app-color-picker>
                </div>
                <div class="col-lg-6">
                  <app-color-picker
                    v-if="dataLoaded"
                    v-model.trim="annotation.color"
                    id="annotation_color"
                    :label="$t('video.annotation_color')"
                  >
                  </app-color-picker>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-video-annotation-position
                    :annotation="annotation"
                  >
                  </app-video-annotation-position>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import ColorPicker from '@/components/form/ColorPicker'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditable from '@/components/shared/MediaEditable'
import { required, requiredIf, minLength, maxLength, url } from 'vuelidate/lib/validators'
import VideoAnnotation from '../../model/VideoAnnotation'
import ButtonClose from '@/components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import VideoAnnotationTypes from '../../model/ValueObject/VideoAnnotationTypes'
import VideoAnnotationRangeSlider from '../../components/video/VideoAnnotationRangeSlider'
import VideoAnnotationGantt from '../../components/video/VideoAnnotationGantt'
import VideoAnnotationPosition from '../../components/video/VideoAnnotationPosition'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import VideoListModal from '../../components/video/VideoListModal'
import CoreApi from '@/api/core'

export default {
  name: 'VideoAnnotationNewView',
  data () {
    return {
      dataLoaded: true,
      error: false,
      annotation: this._.cloneDeep(VideoAnnotation),
      videoAnnotationTypes: VideoAnnotationTypes,
      videoListModal: false
    }
  },
  computed: {
    video () {
      return this.$store.getters['video/detail']
    }
  },
  validations: {
    annotation: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      perex: {
        required: requiredIf(vm => {
          return vm.type === 'article'
        })
      },
      annotationVideoId: {
        required: requiredIf(vm => {
          return vm.type === 'video'
        })
      },
      url: {
        url,
        required: requiredIf(vm => {
          return vm.type === 'external' || vm.type === 'article'
        })
      },
      start: {
        required
      },
      end: {
        required
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appTextarea: Textarea,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable,
    appButtonClose: ButtonClose,
    appColorPicker: ColorPicker,
    appVideoAnnotationRangeSlider: VideoAnnotationRangeSlider,
    appVideoAnnotationGantt: VideoAnnotationGantt,
    appVideoAnnotationPosition: VideoAnnotationPosition,
    appVideoListModal: VideoListModal,
    appPreloaderFull: PreloaderFull
  },
  methods: {
    getAnnotation () {
      this.dataLoaded = false
      this.$store.dispatch('videoAnnotation/fetchOne', this.$route.params.annotationId)
        .then(() => {
          this.annotation = this.$store.getters['videoAnnotation/detail']
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    },
    prepareRequest (annotation) {
      annotation.videoId = parseInt(this.$route.params.id)
      return annotation
    },
    save (redirect = 'edit') {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.dataLoaded = false
      this.$store.dispatch('videoAnnotation/create', this.prepareRequest(this.annotation))
        .then(() => {
          NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
          if (redirect === 'edit') {
            this.getVideo()
            this.annotation = this.$store.getters['videoAnnotation/detail']
            this.$router.push('/videoOld/' + this.video.id + '/annotation/' + this.annotation.id + '/edit')
          }
          if (redirect === 'new') {
            this.annotation = this._.cloneDeep(VideoAnnotation)
            this.$v.reset()
            this.$router.push('/videoOld/' + this.video.id + '/annotation/new')
          }
          if (redirect === 'close') {
            this.$router.push('/videoOld/' + this.video.id + '/edit')
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    },
    setImage (medias) {
      medias.forEach(media => {
        this.annotation.image = media.id
        this.annotation.expanded.image = media
      })
    },
    removeImage () {
      this.annotation.image = null
      this.annotation.expanded.image = null
    },
    setImageMobile (medias) {
      medias.forEach(media => {
        this.annotation.imageMobile = media.id
        this.annotation.expanded.imageMobile = media
      })
    },
    removeImageMobile () {
      this.annotation.imageMobile = null
      this.annotation.expanded.imageMobile = null
    },
    updateRange (range) {
      this.annotation.start = range[0]
      this.annotation.end = range[1]
    },
    getVideo () {
      this.dataLoaded = false
      this.$store.dispatch('video/fetchOne', this.$route.params.id)
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    },
    async setVideo (video) {
      if (video.id) {
        this.annotation.annotationVideoId = video.id
      }
      this.annotation.title = video.meta.title
      if (video.expanded.previewImage) {
        await CoreApi().post('/image', video.expanded.previewImage)
          .then(response => {
            this.setImage([response.data])
          })
          .catch(error => {
            console.error(error)
            NotifyService.setErrorMessage(error)
          })
      }
    },
    showVideoListModal () {
      this.videoListModal = true
    },
    closeVideoListModal () {
      this.videoListModal = false
    }
  },
  beforeMount () {
    this.annotation.start = 0
    this.annotation.end = this.video.videoDuration
  }
}
</script>

<style lang="scss">

</style>

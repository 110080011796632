<template>
  <div class="article-media">
    <div class="item">
      <a>
        <app-preloader v-if="croppedImageLoaded === false"></app-preloader>
        <img
          v-if="croppedImage !== ''"
          :src="croppedImage"
          :title="media.imageCaption"
          width="100%"
          height="100%"
        />
        <i
          v-if="!disabled"
          class="fas fa-edit media-edit"
          :title="$t('media.button_edit_metadata')"
          data-test="media_showMediaEditModal"
          @click="showMediaEditModal"
        >
        </i>
        <i
          v-if="!disabled"
          class="fas fa-download media-download"
          :title="$t('media.button_download')"
          @click="downloadMedia(media)"
        >
        </i>
        <i
          v-if="!disabled && showAddMediaToGallery"
          class="fas fa-images media-add-to-gallery"
          :title="$t('media.button_add_media_to_gallery')"
          data-test="media_addMediaToGallery"
          @click="showAddMediaToGalleryModal"

        >
        </i>
        <i
          v-if="!disabled"
          class="fa fa-trash del media-delete"
          :title="$t('media.button_remove')"
          data-test="media_removeMedia"
          @click="removeMedia()"
        >
        </i>
      </a>
    </div>
    <div class="col-lg" v-if="showMediaInfo">
      <p>
        <strong>{{ $t('media.author') }}:</strong><br>
        {{ media.imageAttribution }}
      </p>
      <p>
        <strong>{{ $t('media.caption') }}:</strong><br>
        {{ media.imageCaption }}
      </p>
    </div>
    <app-media-edit-modal
      v-if="mediaEditModal"
      :selectedMedia="mediaToEdit"
      :site="site"
      :media-usage-type="mediaUsageTypeForEditModal"
      :insert-button-label="insertButtonLabel"
      @add-media="addMedia"
      @close="closeMediaEditModal"
    >
    </app-media-edit-modal>
  </div>
</template>
<script>
import MediaMixin from '../mixins/Media'
import MediaEditModal from '../shared/MediaEditModal'
import Preloader from '../preloader/Preloader'
import DamService from '@/services/dam/DamService'
import { MEDIA_USAGE_TYPE_GALLERY } from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'MediaEditable',
  mixins: [MediaMixin],
  props: {
    media: {
      type: Object
    },
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 202
    },
    showMediaInfo: {
      type: Boolean,
      default: false
    },
    showAddMediaToGallery: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    }
  },
  data () {
    return {
      mediaEditModal: false,
      croppedImageLoaded: true,
      croppedImage: '',
      selectedAsset: null,
      mediaUsageTypeForEditModal: null,
      insertButtonLabel: null
    }
  },
  computed: {
    mediaToEdit () {
      return [
        this.media
      ]
    }
  },
  components: {
    appMediaEditModal: MediaEditModal,
    appPreloader: Preloader
  },
  watch: {
    async mediaToEdit () {
      if (this.media) {
        this.croppedImageLoaded = false
        this.getCroppedImage(this.media)
      } else {
        this.croppedImage = null
      }
    }
  },
  methods: {
    removeMedia () {
      this.$emit('remove-media')
    },
    showMediaEditModal () {
      this.mediaUsageTypeForEditModal = this.mediaUsageType
      this.insertButtonLabel = this.$t('modal.done')
      this.mediaEditModal = true
    },
    showAddMediaToGalleryModal () {
      this.mediaUsageTypeForEditModal = MEDIA_USAGE_TYPE_GALLERY
      this.insertButtonLabel = null
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    addMedia (media) {
      if (this.mediaUsageTypeForEditModal === MEDIA_USAGE_TYPE_GALLERY) {
        this.$emit('add-media-to-gallery', media)
      } else {
        this.$emit('set-media', media)
      }
    },
    getCroppedImage (media) {
      this.croppedImage = DamService.getDamImagePathForMedia(media)
      this.croppedImageLoaded = true
    }
  },
  async created () {
    this.getCroppedImage(this.media)
  }
}
</script>

<style lang="scss">
.article-media {
  a {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    object-fit: cover;
  }

  .item {
    box-sizing: border-box;
    margin-bottom: rem(3px);
    display: flex;
    position: relative;
    padding: 0;
    cursor: pointer;
  }

  .media-edit,
  .media-add-to-gallery,
  .media-delete,
  .media-download {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 10px;
    display: none;
    color: #fff;
  }

  .media-edit {
    left: 0;
    background: #2f3d4a;
  }

  .media-download {
    left: 40px;
    background: #2f3d4a;
  }

  .media-add-to-gallery {
    background: #2f3d4a;
    right: 36px;
  }

  .media-delete {
    right: 0;
    background: #dc3545;
  }

  .item a:hover {
    .media-edit,
    .media-add-to-gallery,
    .media-delete,
    .media-download {
      display: block;
    }
  }
}
</style>

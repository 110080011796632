<template>
  <div class="modal-backdrop fade show">
    <div class="eagle-preloader">
      <div>
        <svg
          class="circular"
          viewBox="25 25 50 50"
        >
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            :stroke-width="strokeWidth"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreloaderFull',
  props: {
    strokeWidth: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-backdrop.show {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    margin: -73px -5px -50px -5px;
    z-index: 49;
  }
  .eagle-preloader {
    display: block;
    z-index: 50;
  }
  .circular {
    margin: 150px auto;
    z-index: 50;
  }
</style>

<template>
  <div>
    <Preloader v-if="isLoading" />
    <Tabs
      ref="cropTabs"
      :tab-names="availableCrops.map(crop => crop.name)"
      @tab-changed="onCropTabChanged"
    >
      <template #afterTabs>
        <span class="selected-tab-info">
          <i class="fas fa-info-circle" />
          {{ $t('dam.aspect_ratio_will_be_used', { aspectRatio: availableCrops[activeTab].name}) }}
        </span>
      </template>
    </Tabs>
    <div ref="cropperContainer" style="height: 100%;">
      <VueCropper
        v-if="!fetchingAsset"
        :key="`crop-key-${media.damMediaEmbed.damId}-${cropperAspectRatio}-${singleMediaSelected}-${cropperKey}`"
        v-show="!isLoading"
        id="croppedImage"
        ref="cropper"
        :guides="true"
        :view-mode="1"
        drag-mode="crop"
        :auto-crop-area="1"
        :min-container-width="croppedImageWidth"
        :min-container-height="croppedImageHeight"
        :background="true"
        :rotatable="true"
        :src="getMediaPathForCropper()"
        alt="Source Image"
        :ready="onCropperReady"
        center
        :aspect-ratio=cropperAspectRatio
        :check-cross-origin="false"
        :cropend="onCropRoiModified"
      />
    </div>
    <br />
    <h6 class="card-subtitle text-center">{{ media.imageAttribution }}</h6>
    <div v-if="media.dimensions" class="check-image-warning">
      <p
        v-if="media.size > maxFileSize"
        class="alert alert-warning"
      >
        <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_max_size') }}
      </p>
      <p
        v-if="media.dimensions.width < minFileWidth"
        class="alert alert-warning"
      >
        <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_low_quality') }}
        {{ media.dimensions.width }}x{{ media.dimensions.height }} px
      </p>
    </div>
  </div>
</template>

<script>

import VueCropper from 'vue-cropperjs'
import Preloader from '@/components/preloader/Preloader'
import Tabs from '@/components/shared/Tabs'
import CropMixin from '@/components/mixins/Crop'
import { mapState } from 'vuex'
import NotifyService from '@/services/NotifyService'
import DamService from '@/services/dam/DamService'

const MAX_FILE_SIZE = 103809024
const MIN_FILE_WIDTH = 600

export default {
  name: 'MediaEditModalCropper',
  mixins: [CropMixin],
  props: {
    media: {
      type: Object,
      required: true
    },
    singleMediaSelected: {
      type: Boolean,
      required: true
    },
    availableCrops: {
      type: Array,
      required: true
    }
  },
  components: {
    VueCropper,
    Preloader,
    Tabs
  },
  data () {
    return {
      croppedImageWidth: 200,
      croppedImageHeight: 100,
      cropperKey: 0,
      fetchingAsset: true,
      dataLoaded: false,
      cropperAspectRatio: 16 / 9,
      activeTab: 0,
      loadedMedia: null,
      loadedAsset: null,
      maxFileSize: MAX_FILE_SIZE,
      minFileWidth: MIN_FILE_WIDTH
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isLoading () {
      return !this.dataLoaded || this.callingAPI || this.fetchingAsset
    },
    activeTabName () {
      return this.availableCrops[this.activeTab].name
    },
    cropPositionEmbed () {
      if (this.media?.damMediaEmbed?.damId) {
        return this.$store.getters['dam/cropPositionEmbed'][this.media.damMediaEmbed.damId]
      }
      return {}
    }
  },
  methods: {
    updateCropperDimensions () {
      if (this.$refs.cropperContainer) {
        const containerWidth = this.$refs.cropperContainer.offsetWidth - 30
        this.croppedImageHeight = Math.min(this.loadedAsset.height, 480)
        this.croppedImageWidth = Math.min(Math.max(this.loadedAsset.width, containerWidth), containerWidth)
        this.cropperKey += 1
      }
    },
    getMediaPathForCropper () {
      return DamService.getDamImagePathForAsset(this.loadedAsset, { dynamic: true })
    },
    /**
     * Store current crop into vuex store by tab index.
     */
    storeTabCrop (tabIndex) {
      const roi = this.getCropperCurrentRoi()
      const tabName = this.availableCrops[tabIndex].name
      const payload = {
        damId: this.media.damMediaEmbed.damId,
        aspectRatio: tabName,
        roi
      }
      this.$store.commit('dam/changeRoiOfCropPositionEmbed', payload)
    },
    onCropRoiModified () {
      this.storeTabCrop(this.activeTab)
    },
    onCropTabChanged ({ index }) {
      // update the underlying crop component
      this.activeTab = index
      this.setCropAspectRatio(index)
      this.setCropSelectedByAuthor(index)
    },
    setCropTab (tabIndex) {
      this.$refs.cropTabs.setActiveTab(tabIndex)
      this.onCropTabChanged({ index: tabIndex })
    },
    setCropAspectRatio (tabIndex) {
      this.cropperAspectRatio = this.availableCrops[tabIndex].value
    },
    setCropSelectedByAuthor (tabIndex) {
      const payload = {
        damId: this.media.damMediaEmbed.damId,
        cropName: this.availableCrops[tabIndex].name
      }
      this.$store.commit('dam/setCropSelectedByAuthor', payload)
    },
    getCropperCurrentRoi () {
      const { assetWidth, assetHeight } = this.cropPositionEmbed
      const cropper = this.getCropperObject()
      let roi = cropper.getData()
      const imageData = cropper.getImageData()
      const widthRatio = imageData.naturalWidth / assetWidth
      const heightRatio = imageData.naturalHeight / assetHeight
      roi = {
        x: Math.round(roi.x / widthRatio),
        y: Math.round(roi.y / heightRatio),
        width: Math.round(roi.width / widthRatio),
        height: Math.round(roi.height / heightRatio)
      }
      return roi
    },
    async getCropPositionEmbedFromMedia ({ width, height }) {
      const cropPositionEmbed = {
        assetWidth: Math.round(width),
        assetHeight: Math.round(height)
      }

      const crops = {}

      const fillExistingCrops = (mediaRatioUsages) => {
        const mediaRatioUsagesMap = mediaRatioUsages.reduce((acc, ratioUsage) => {
          acc[ratioUsage.ratio] = ratioUsage
          return acc
        }, {})

        let selectedByAuthorCropIndex = 0
        this.availableCrops.forEach((crop, index) => {
          const ratioUsage = mediaRatioUsagesMap[crop.ratioId]
          if (ratioUsage) {
            if (ratioUsage.selectedByAuthor) {
              selectedByAuthorCropIndex = index
            }
            crops[crop.name] = {
              x: Math.round(ratioUsage.cropLeftUpperX),
              y: Math.round(ratioUsage.cropLeftUpperY),
              width: Math.round(ratioUsage.cropRightBottomX - ratioUsage.cropLeftUpperX),
              height: Math.round(ratioUsage.cropRightBottomY - ratioUsage.cropLeftUpperY)
            }
          }
        })
        return selectedByAuthorCropIndex
      }

      const fillBaseCrops = async (missingCrops) => {
        const payload = {
          width,
          height,
          ratios: missingCrops.map(availableCrop => ({ x: +availableCrop.ratioX, y: +availableCrop.ratioY }))
        }
        const baseCrops = await this.$store.dispatch('dam/getBaseCropsForRatios', payload)
        const error = this.$store.getters['dam/error']
        if (error) {
          console.error(error)
          NotifyService.setErrorMessage(error)
        }

        let mostSuitableCropIndex = 0
        baseCrops.forEach((crop, index) => {
          crops[missingCrops[index].name] = {
            x: Math.round(crop.leftUpperX),
            y: Math.round(crop.leftUpperY),
            width: Math.round(crop.rightBottomX - crop.leftUpperX),
            height: Math.round(crop.rightBottomY - crop.leftUpperY)
          }
          if (crop.mostSuitable) {
            mostSuitableCropIndex = index
          }
        })
        // most suitable crop by the image's width and height
        return mostSuitableCropIndex
      }

      let tabToSelect
      if (this.loadedMedia?.mediaRatioUsages?.length > 0) {
        tabToSelect = fillExistingCrops(this.loadedMedia.mediaRatioUsages)
        const missingCrops = this.availableCrops.filter(availableCrop => !crops[availableCrop.name])
        if (missingCrops.length > 0) {
          await fillBaseCrops(missingCrops)
        }
      } else {
        tabToSelect = await fillBaseCrops(this.availableCrops)
      }

      cropPositionEmbed.crops = crops
      return { cropPositionEmbed, tabToSelect }
    },
    // CROP
    async onCropperReady () {
      if (!this.dataLoaded) {
        // load crop and ratios when data is currently loading (when the modal just opened)
        const { damId } = this.media.damMediaEmbed
        const { width, height } = this.loadedAsset
        const { cropPositionEmbed, tabToSelect } = await this.getCropPositionEmbedFromMedia({ width, height })
        this.$store.commit('dam/setCropPositionEmbed', { damId, cropPositionEmbed })
        this.setCropTab(tabToSelect)
        this.$emit('loaded')
        this.dataLoaded = true
      }
      this.setCrop(this.cropPositionEmbed)
    },
    setCrop (cropPositionEmbed) {
      const cropper = this.getCropperObject()
      if (cropper) {
        const imageData = cropper.getImageData()
        const maxCropWidth = imageData.naturalWidth - 1 // "-1" accounts for rounding errors
        const maxCropHeight = imageData.naturalHeight - 1 // "-1" accounts for rounding errors
        const widthRatio = imageData.naturalWidth / cropPositionEmbed.assetWidth
        const heightRatio = imageData.naturalHeight / cropPositionEmbed.assetHeight
        const activeTabCrop = cropPositionEmbed.crops[this.activeTabName]
        const dataProperties = {
          x: activeTabCrop.x * widthRatio,
          y: activeTabCrop.y * heightRatio,
          width: Math.min(activeTabCrop.width * widthRatio, maxCropWidth),
          height: Math.min(activeTabCrop.height * heightRatio, maxCropHeight)
        }
        cropper.setData(dataProperties)
      }
    },
    getCropperObject () {
      if (this.$refs.cropper.length === 0) {
        return null
      }
      if (Array.isArray(this.$refs.cropper)) {
        return this.$refs.cropper[0].cropper
      } else {
        return this.$refs.cropper.cropper
      }
    }
  },
  async created () {
    this.fetchingAsset = true
    this.loadedAsset = await this.$store.dispatch('dam/fetchOne', this.media.damMediaEmbed.damId)
    this.media.keywords = this.loadedAsset.keywords
    this.media.dateTimeOriginal = this.loadedAsset.dateTimeOriginal
    if (this.media.id) {
      this.loadedMedia = await this.$store.dispatch('media/fetchOne', this.media.id)
    }
    this.fetchingAsset = false
    this.updateCropperDimensions()
  }
}
</script>

<style lang="scss" scoped>
.selected-tab-info {
  @include font(500 15px "Roboto");
  margin-left: auto;
  color: #6599fe;
}
</style>

<template>
  <section>
    <div class="annotation-gantt">
      <template v-for="(annotation, index) in video.annotations">
        <div class="annotation-row" :key="`item-${index}`">
          <div
            class="annotation-block"
            :style="getWidthAndPosition(annotation)"
            @click="editAnnotation(annotation)"
          >
            <span>{{ annotation.title }}</span>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VideoAnnotationGantt',
  props: {
    video: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {
    getVideoRoute () {
      if (this.$route && this.$route.name.includes('old')) {
        return 'videoOld'
      } else {
        return 'video'
      }
    }
  },
  methods: {
    getWidthAndPosition (annotation) {
      const step = 100 / (this.video.videoDuration)
      const left = step * annotation.start
      const width = step * (annotation.end - annotation.start)
      return 'width: ' + width + '%; left: ' + left + '%;'
    },
    editAnnotation (annotation) {
      this.$router.push('/' + this.getVideoRoute + '/' + this.video.id + '/annotation/' + annotation.id + '/edit')
    }
  }
}
</script>

<style lang="scss" scoped>
.annotation-gantt {
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.annotation-row {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  padding: 1px 0;
  width: 100%;
  height: 30px;
  position: relative;
  background: #f7f9fa;
}

.annotation-block {
  position: absolute;
  overflow: hidden;
  top: 1px;
  background-color: #e6b866;
  border-left: 10px solid #e69b3a;
  padding: 6px 5px;
  height: 28px;
  cursor: pointer;

  span {
    float: left;
    font-size: 11px;
    font-weight: 600;
    color: #000000;
  }
}
</style>

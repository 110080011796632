<template>
    <span v-if="!vlm">
        <button class="btn btn-info" @click="showDamUrlModal">
            <i class="fas fa-link"></i>
            {{ $t('buttons.upload_from_url') }}
        </button>
        <app-dam-url-modal
          v-if="damUrlModal"
          @close="closeDamUrlModal"
          @show-dam-upload-modal="showDamUploadModal"
          :urlLabel="urlLabel"
        >
        </app-dam-url-modal>
        <app-dam-upload-modal
          ref="uploadModal"
          v-if="damUploadModal"
          :files="filesFromUrl"
          :upload-source="uploadSource"
          only-save-metadata
          @close="closeDamUploadModal"
          @set-media="setMedia"
        >
        </app-dam-upload-modal>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import DamUploadModal from './damUploadModal/DamUploadModal'
import DamUrlModal from './DamUrlModal'
import { SOURCE_DAM_URL } from '@/model/ValueObject/DamUploadSources'

export default {
  name: 'DamUrlButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    urlLabel: {
      type: String,
      default () {
        return this.$t('modal.image_url')
      }
    }
  },
  data () {
    return {
      damUploadModal: false,
      damUrlModal: false,
      filesFromUrl: [],
      uploadSource: SOURCE_DAM_URL
    }
  },
  components: {
    appDamUploadModal: DamUploadModal,
    appDamUrlModal: DamUrlModal
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  methods: {
    showDamUploadModal (files) {
      this.filesFromUrl = files
      this.damUploadModal = true
      this.$nextTick(() => {
        this.$refs.uploadModal.uploadFinished()
        this.damUrlModal = false
      })
    },
    closeDamUploadModal () {
      this.damUploadModal = false
    },
    showDamUrlModal () {
      this.damUrlModal = true
    },
    closeDamUrlModal () {
      this.damUrlModal = false
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    }
  }
}
</script>

export default [
  {
    id: 'external',
    title: '+ na web'
  },
  {
    id: 'video',
    title: '+ video'
  },
  {
    id: 'article',
    title: '+ na článok'
  }
]

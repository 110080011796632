<template>
  <div class="position">
    <label>{{ $t('video.previewPosition') }}</label>
    <div class="row position-grid text-center m-t-10">
      <div class="col-md-4 border-top">
        <input type="radio" v-model="annotation.position" id="pos-tl" class="radio-col-black with-gap" value="tl">
        <label for="pos-tl">TL</label>
      </div>
      <div class="col-md-4 border-top">
        <input type="radio" v-model="annotation.position" id="pos-tc" class="radio-col-black with-gap" value="tc">
        <label for="pos-tc">TC</label>
      </div>
      <div class="col-md-4 border-top no-border-right">
        <input type="radio" v-model="annotation.position" id="pos-tr" class="radio-col-black with-gap" value="tr">
        <label for="pos-tr">TR</label>
      </div>
      <div class="col-md-4">
        <input type="radio" v-model="annotation.position" id="pos-vc" class="radio-col-black with-gap" value="vc">
        <label for="pos-vc">CL</label>
      </div>
      <div class="col-md-4">
        <input type="radio" v-model="annotation.position" id="pos-c" class="radio-col-black with-gap" value="c">
        <label for="pos-c">CC</label>
      </div>
      <div class="col-md-4 no-border-right">
        <input type="radio" v-model="annotation.position" id="pos-cr" class="radio-col-black with-gap" value="cr">
        <label for="pos-cr">CR</label>
      </div>
      <div class="col-md-4 no-border">
        <input type="radio" v-model="annotation.position" id="pos-bl" class="radio-col-black with-gap" value="bl">
        <label for="pos-bl">BL</label>
      </div>
      <div class="col-md-4 no-border">
        <input type="radio" v-model="annotation.position" id="pos-bc" class="radio-col-black with-gap" value="bc">
        <label for="pos-bc">BC</label>
      </div>
      <div class="col-md-4 no-border no-border-right">
        <input type="radio" v-model="annotation.position" id="pos-br" class="radio-col-black with-gap" value="br">
        <label for="pos-br">BR</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoAnnotationPosition',
  props: {
    annotation: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.position {
  .position-grid {
    padding: 0 20px;

    div {
      padding-top: 5px;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }

  .position-grid div.border-top {
    border-top: 1px solid #ccc;
  }

  .position-grid div.no-border-right {
    border-right: 0;
  }

  label {
    margin-bottom: 0;
  }
}
</style>

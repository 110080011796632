<template>
  <section class="dam-filter">
    <form class="dam-filter__form" @submit.prevent>
      <div class="dam-filter__form-panel-1">
        <div class="filter-row three-columns-grid">
          <Input
            v-model="filter.text"
            id="filter_fulltext"
            :label="$t('dam.filter.fulltext_search')"
            :placeholder="$t('dam.filter.fulltext_search_placeholder')"
            disable-form-group
          />
          <MultiSelect
            v-model="filter.uploadSourceCodes"
            id="filter_uploadSourceCodes"
            :options="uploadSources"
            label="title"
            track-by="id"
            :label-desc="$t('dam.filter.upload_source')"
            disable-form-group
          />
          <div class="created-by-wrapper">
            <MultiSelect
              class="created-by-wrapper__select"
              v-model="filter.createdBy"
              :options="ssoUsers"
              :custom-label="user => `${user.name}`"
              track-by="id"
              id="filter_createdBy"
              :label-desc="$tc('dam.filter.created_by')"
              disable-form-group
            />
            <div class="created-by-wrapper__buttons">
              <button
                v-for="(button, buttonIndex) in createdByButtons"
                :key="`item-${buttonIndex}`"
                :class="{
                  'created-by-wrapper__buttons__button': true,
                  'created-by-wrapper__buttons__button--selected': button.selected,
                }"
                type="button"
                v-html="button.html"
                @click="toggleCreatedByButton(button.value)"
              />
            </div>
          </div>
        </div>
        <template v-if="advancedFilter">
          <div class="filter-row four-columns-grid">
            <MultiSelect
              v-if="isPdf"
              v-model="filter.categoryCodes"
              id="asset_metadata_category"
              :options="damCategories"
              label="title"
              track-by="id"
              :label-desc="$t('dam.category')"
              disable-form-group
            />
            <Input
              v-model="filter.author"
              id="filter_author"
              :label="$t('dam.filter.author')"
              disable-form-group
            />
            <Select
              v-model="filter.pageSortData.orderBy"
              id="filter_orderBy"
              :options="orderByOptions"
              :label="$t('dam.filter.show_by')"
              no-empty-value
              disable-form-group
            />
            <MultiSelect
              v-if="!isPdf"
              v-model="filter.albums"
              :options="photoAlbums"
              :label-desc="$t('dam.filter.photo_series')"
              label="title"
              track-by="id"
              id="filter_album"
              disable-form-group
            />
            <Input
              v-model="filter.keywords"
              id="filter_keywords"
              :label="$t('dam.filter.keywords')"
              :placeholder="$t('dam.filter.keywords')"
              disable-form-group
            />
          </div>
          <div class="filter-row dam-filter__separator-horizontal" />
          <div class="filter-row four-columns-grid">
            <MultiSelect
              v-if="!isPdf"
              v-model="filter.orientationCodes"
              id="filter_orientation"
              :options="orientations"
              label="title"
              track-by="id"
              :label-desc="$t('dam.filter.orientation')"
              disable-form-group
            />
            <Select
              v-if="!isPdf"
              v-model="filter.size"
              id="filter_size"
              :options="sizes"
              :label="$t('dam.filter.size')"
              :placeholder="$t('dam.filter.size')"
              disable-form-group
            />
            <Input
              v-model="filter.description"
              id="filter_description"
              :label="$t('dam.filter.description')"
              :placeholder="$t('dam.filter.description')"
              disable-form-group
            />
            <Input
              v-if="!isPdf"
              v-model="filter.personsInImage"
              id="filter_personInImage"
              :label="$t('dam.filter.person_in_image')"
              :placeholder="$t('dam.filter.person_in_image')"
              disable-form-group
            />
          </div>
          <div class="filter-row last-three-columns-grid">
            <Select
              v-if="!isPdf"
              v-model="filter.resolution"
              id="filter_dimensions"
              :options="resolutions"
              :label="$t('dam.filter.resolution')"
              :placeholder="$t('dam.filter.resolution')"
              disable-form-group
            />
            <Input
              v-model="filter.entityUuids"
              id="filter_id"
              :label="$t('dam.filter.id')"
              :placeholder="$t('dam.filter.id')"
              disable-form-group
            />
            <MultiSelect
              v-if="!isPdf"
              v-model="filter.siteLocks"
              :options="restrictedSites"
              :label-desc="$t('dam.filter.restricted_sites_placeholder')"
              :placeholder="$t('dam.filter.restricted_sites_placeholder')"
              label="title"
              track-by="id"
              id="filter_restrictedSites"
            />
          </div>
        </template>
      </div>
      <div class="dam-filter__form-panel-2" >
        <div class="dam-filter__form-panel-2__filter-buttons">
          <ButtonSearch @click="filterAssets"/>
          <ButtonShowAdvancedFilter
            :on-click="toggleAdvancedFilter"
            :is-enabled="advancedFilter"
            color-enabled
          />
          <div class="dam-filter__separator-vertical" />
          <DamUploadButton
            multiple
            @set-media="$emit('set-media', $event)"
          />
          <DamUrlButton
            @set-media="$emit('set-media', $event)"
          />
        </div>
        <template v-if="advancedFilter">
          <div class="filter-row two-columns-grid-with-reset">
            <ButtonResetFilter class="dam-filter__form-panel-2__reset-button" :on-click="resetFilters" />
          </div>
          <div class="filter-row two-columns-grid advanced-filter">
            <Datepicker
              type="date"
              data-test="dam_filter_createdSince"
              v-model="filter.createdSince"
              id="filter_dateFrom"
              :label="$t('dam.filter.created_since')"
              :placeholder="$t('dam.filter.created_since')"
              disable-form-group
            />
            <Datepicker
              type="date"
              data-test="dam_filter_createdUntil"
              v-model="filter.createdUntil"
              id="filter_dateTo"
              :label="$t('dam.filter.created_until')"
              :placeholder="$t('dam.filter.created_until')"
              disable-form-group
            />
          </div>
          <div class="filter-row two-columns-grid">
            <Datepicker
              type="date"
              data-test="dam_filter_takenSince"
              v-model="filter.takenSince"
              id="filter_takenSince"
              :label="$t(`dam.filter${isPdf ? '.pdf' : ''}.taken_since`)"
              :placeholder="$t(`dam.filter${isPdf ? '.pdf' : ''}.taken_since`)"
              disable-form-group
            />
            <Datepicker
              type="date"
              data-test="dam_filter_takenUntil"
              v-model="filter.takenUntil"
              id="filter_takenUntil"
              :label="$t(`dam.filter${isPdf ? '.pdf' : ''}.taken_until`)"
              :placeholder="$t(`dam.filter${isPdf ? '.pdf' : ''}.taken_until`)"
              disable-form-group
            />
          </div>
        </template>
      </div>
      <div class="filter-row dam-filter__separator-horizontal" />
    </form>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import DamFilter from '@/model/DamFilter'
import { AVAILABLE_PDF_SOURCES, AVAILABLE_SOURCES } from '@/model/ValueObject/DamUploadSources'
import MultiSelect from '@/components/form/select/MultiSelect'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import ButtonSearch from '@/components/buttons/ButtonSearch'
import ButtonResetFilter from '@/components/buttons/ButtonResetFilter'
import ButtonShowAdvancedFilter from '@/components/buttons/BtnArticleShowAdvancedFilter'
import DamUploadButton from '@/components/shared/DamUploadButton'
import DamUrlButton from '@/components/dam/DamUrlButton'
import { MODULE_DAM } from '@/model/ValueObject/UserPermissionModules'
import { PREDEFINED_DAM_PDF_USERS, PREDEFINED_DAM_USERS } from '@/services/dam/DamService'
import DamPdfCategoryMixin from '@/components/mixins/valueObject/DamPdfCategoryMixin'

export default {
  name: 'DamFilter',
  mixins: [DamPdfCategoryMixin],
  props: {
    pagination: {
      type: Boolean,
      required: true
    },
    defaultFilter: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(DamFilter)
    }
  },
  computed: {
    ...mapGetters('dam', [
      'isPdf', 'advancedFilter'
    ]),
    photoAlbums () {
      return this.$store.getters['dam/photoAlbums'].map(photoAlbum => ({ id: photoAlbum, title: photoAlbum }))
    },
    createdByButtons () {
      if (this.$store.getters.vlm) {
        return []
      }
      const users = this.isPdf ? PREDEFINED_DAM_PDF_USERS : PREDEFINED_DAM_USERS
      return Object.keys(users)
        .map((key, index) => ({
          id: -index,
          value: key,
          html: users[key],
          selected: false
        }))
    },
    ssoUsers () {
      return this.$store.getters['beUser/all']
    },
    uploadSources () {
      return this.isPdf ? AVAILABLE_PDF_SOURCES : AVAILABLE_SOURCES
    },
    orderByOptions () {
      return [
        { id: 'CreatedAt', title: this.$t('dam.filter.show_by_upload_date') },
        { id: 'OriginalDateTime', title: this.$t('dam.filter.show_by_original_date') }
      ]
    },
    sizes () {
      return [1048576, 2097152, 3145728, 4194304, 5242880]
        .map((size, index) => ({
          id: size,
          title: this.$t('dam.filter.size_gt', { size: index + 1 })
        }))
    },
    resolutions () {
      return [524288, 1048576, 2097152, 3145728, 4194304, 5242880]
        .map((resolution, index) => ({
          id: resolution,
          title: this.$t('dam.filter.resolution_gt', { resolution: index === 0 ? 0.5 : index })
        }))
    },
    orientations () {
      return ['HRZN', 'VRTC', 'SQRE']
        .map(orientation => ({
          id: orientation,
          title: this.$t(`dam.filter.orientation_${orientation}`)
        }))
    },
    restrictedSites () {
      return this.$store.getters['site/userSites'](MODULE_DAM)
    },
    availableSites () {
      return this.restrictedSites.map(function (restrictedSite) {
        return restrictedSite.name
      })
    }
  },
  components: {
    DamUploadButton,
    DamUrlButton,
    ButtonShowAdvancedFilter,
    ButtonResetFilter,
    ButtonSearch,
    Input,
    Select,
    Datepicker,
    MultiSelect
  },
  methods: {
    filterAssets () {
      this.$store.commit('dam/setMaxCreatedAt', '')
      this.$store.commit('dam/setPage', 1)
      this.filter.availableSites = this.availableSites
      this.$store.commit('dam/setFilter', this.filter)
      this.$store.dispatch(this.pagination ? 'dam/fetchPage' : 'dam/fetch')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(DamFilter)
      this.resetCreatedByButton()
      this.filterAssets()
    },
    resetCreatedByButton () {
      this.createdByButtons.forEach(button => {
        button.selected = false
      })
      this.createdByDisabled = false
    },
    toggleAdvancedFilter () {
      this.$store.commit('dam/toggleAdvancedFilter')
    },
    toggleCreatedByButton (buttonValue) {
      const toggledButton = this.createdByButtons.find(button => button.value === buttonValue)
      toggledButton.selected = !toggledButton.selected
      const filteredValue = this.ssoUsers.find(user => user.id === buttonValue)
      if (toggledButton.selected) {
        this.filter.createdBy.push(filteredValue)
        return
      }
      this.filter.createdBy = this.filter.createdBy.filter(user => user.id !== buttonValue)
    }
  },
  created () {
    this.filter.availableSites = this.availableSites
    if (this.defaultFilter) {
      this.filter = this.defaultFilter
    }
    this.$store.commit('dam/setFilter', this.filter)
    this.$store.commit('dam/setIsPdf', this.isPdf)
    this.$store.dispatch('dam/fetchPhotoAlbums')
  }
}
</script>

<style lang="scss" scoped>
.dam-filter {
  display: grid;
  &__form {
    display: grid;
    grid-gap: rem(20px);
    grid-auto-flow: dense;
    @include bp(0 11) {
      grid-template-columns: 1fr;
    }
    @include bp(11) {
      grid-template-columns: 75% max-content;
    }
  }
  &__form-panel-1 {
    .filter-row {
      margin-top: 1rem;
      display: grid;
      grid-gap: rem(10px);
    }

    @include bp(0 11) {
      .three-columns-grid, .four-columns-grid, .last-three-columns-grid {
        grid-template-columns: 1fr;
      }
    }
    @include bp(11) {
      .three-columns-grid {
        grid-template-columns: 1fr 0.66fr 1fr;
      }
      .four-columns-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .last-three-columns-grid {
        grid-template-columns: 1fr 1fr 2fr;
      }
    }
  }
  &__form-panel-2 {
    margin-top: rem(40px);
    &__filter-buttons {
      display: grid;
      grid-template-columns: max-content max-content max-content max-content max-content;
      grid-gap: rem(10px);
    }
    .filter-row {
      margin-top: rem(18px);
      margin-bottom: rem(21px);
      display: grid;
      grid-gap: rem(10px);
    }
    @include bp(0 11) {
      order: -1;
      .two-columns-grid, .two-columns-grid-with-reset {
        grid-template-columns: 1fr;
      }
    }
    @include bp(11) {
      .two-columns-grid {
        grid-template-columns: 0.65fr 0.65fr;
      }
      .two-columns-grid-with-reset {
        grid-template-columns: 1fr max-content;
      }
    }
    &__reset-button {
      margin-top: rem(24px);
      width: max-content;
    }
    .advanced-filter {
      margin-top: rem(36px);
    }
  }
  &__separator-vertical {
    margin: 0 rem(10px);
    background: #D1DBE4;
    width: rem(1px);
    height: rem(40px);
  }
  &__separator-horizontal {
    width: 133%;
    height: rem(1px);
    background: #d1dbe4;
  }
}

.created-by-wrapper {
  position: relative;
  &__buttons {
    position: absolute;
    right: 0;
    top: rem(-8px);
    z-index: 2;
    display: flex;
    gap: rem(5px);
    &__button {
      @include font(700 13px "Roboto");
      color: #5F6368;
      background-color: #E3ECFD;
      border: 1px solid #D1DBE4;
      border-radius: rem(6px);
      cursor: pointer;
      height: rem(30px);
      &--selected {
        background-color: darken(#E3ECFD, 30%);
        color: #FFFFFF;
      }
    }
  }
}
</style>

<template>
  <button
    type="button"
    class="btn-reset-filter"
    data-test="button_reset_filter"
    @click="onClick"
  >
    {{ $t('reset_filter') }}
  </button>
</template>

<script>
export default {
  name: 'ButtonResetFilter',
  props: {
    onClick: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-reset-filter {
    padding-left: rem(15px);
    padding-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #465674;
    background: #fff;
    border-radius: rem(6px);
    cursor: pointer;
    height: rem(40px);
    transition: all 100ms;
    display: flex;
    align-items: center;
    border: solid 1px #d1dbe4;
    &:hover {
      color: #6599fe;
      border: solid 1px #6599fe;
    }
  }
</style>

<template>
  <div class="mode-tabs">
    <div
      v-for="(tabName, index) in tabNames"
      :key="`tab-${index}`"
      class="mode-tabs__tab"
      :class="{'mode-tabs__tab--active': activeTab === index}"
      @click="tabChanged({ tabName, index })"
    >
      <slot :tabName="tabName" :index="index">
        {{ tabName }}
      </slot>
    </div>
    <slot name="afterTabs" />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabNames: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
    tabChanged ({ tabName, index }) {
      this.activeTab = index
      this.$emit('tab-changed', { tabName, index })
    },
    /**
     * Usually called from a parent component.
     */
    setActiveTab (index) {
      this.activeTab = index
    }
  }
}
</script>

<style lang="scss" scoped>
.mode-tabs {
  display: flex;
  gap: rem(16px);
  margin-bottom: rem(16px);
  &__tab {
    @include font(500 14px "Roboto");
    color: #465674;
    cursor: pointer;
    position: relative;
    padding-bottom: rem(5px);
    &:hover:not(.mode-tabs__tab--active) {
      color: #000;
    }
    &--active {
      color: #6599FE;
      &::after {
        position: absolute;
        left: 0;
        bottom: rem(-7px);
        width: 100%;
        height: rem(3px);
        background: #6599FE;
        content: '';
        z-index: 1;
      }
    }
  }
}
</style>

<template>
  <app-modal
    :title="$t('modal.media_url_header')"
    modal-size="modal-lg"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-8">
          <app-input
            v-model.trim="search.url"
            :label="urlLabel"
            :maxlength="999"
            @input="getImageName"
            id="search_url"
          />
          <app-input
            v-model.trim="search.imageName"
            :label="$t('modal.image_name')"
            :maxlength="999"
            id="image_name"
          />
        </div>
        <div class="col-4">
          <div class="m-t-20 text-left">
            <button
              @click="extractFromUrl"
              type="button"
              class="btn btn-info "
            >
              <i class="fa fa-check"></i> {{ $t('modal.select') }}
            </button>
          </div>
        </div>
      </div>
      <app-preloader v-if="callingAPI"></app-preloader>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import { mapGetters, mapState } from 'vuex'
import Input from '../form/inputs/Input'
import Preloader from '../preloader/Preloader'
import DamApi from '../../api/dam'
import { SOURCE_DAM_URL } from '@/model/ValueObject/DamUploadSources'
import { DAM_CATEGORY_DEFAULT } from '@/components/mixins/valueObject/DamPdfCategoryMixin'
import NotifyService from '@/services/NotifyService'
import MediaService from '@/services/media/MediaService'
import DamPdfService from '@/services/dam/DamPdfService'

export default {
  name: 'DamUrlModal',
  props: {
    urlLabel: {
      type: String
    }
  },
  data () {
    return {
      search: {
        url: '',
        imageName: ''
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('dam', [
      'isPdf'
    ]),
    imageSettings () {
      return this.$store.getters['media/imageSettings']
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appPreloader: Preloader
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async extractFromUrl () {
      this.$store.commit('TOGGLE_LOADING')
      const bodyData = {
        url: this.search.url,
        uploadSource: SOURCE_DAM_URL,
        imageName: this.search.imageName,
        pdfName: this.search.imageName,
        categoryCode: undefined,
        siteLocks: [],
        unlockAfterPublish: true,
        maxCropWidth: this.imageSettings.maxCropWidth,
        maxCropHeight: this.imageSettings.maxCropHeight
      }
      try {
        const response = await DamApi().post(`/${this.isPdf ? 'pdf' : 'image'}/from-url`, bodyData)
        let file
        if (this.isPdf) {
          file = response.data
          file.pdfPath = DamPdfService.getPdfPath(response.data)
          file.categoryCode = DAM_CATEGORY_DEFAULT
        } else {
          const { imageAsset, uploadedImageAsset, wasCreated } = response.data
          file = MediaService.convertToUploadedFile({
            imageAsset,
            uploadedImageAsset,
            wasCreated,
            fileBase64: null
          })
        }
        this.$emit('show-dam-upload-modal', [file])
      } catch (error) {
        NotifyService.setErrorMessage(error.response?.data?.title ?? error)
        console.error(error)
      } finally {
        this.$store.commit('TOGGLE_LOADING')
      }
    },
    getImageName () {
      this.search.imageName = this.search.url.split('/').pop()
    }
  }
}
</script>

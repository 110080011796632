<template>
  <section>
    <div>
      <router-link
        tag="a"
        class="btn-article-create"
        :to="{ name: routeName }"
        :title="$t('buttons.create')"
        :target="linkTarget"
        data-test="article_btn_create"
      >
        {{ label ? label : $t('buttons.create') }}
      </router-link>
    </div>
  </section>
</template>
<script>
export default {
  name: 'CreateButton',
  props: {
    routeName: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    linkTarget: {
      type: String,
      default: '_self' // '_self' | '_blank'
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-article-create {
    padding-left: rem(15px);
    padding-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #fff;
    background: #6599FE;
    border: none;
    border-radius: rem(6px);
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    &:hover {
      background: darken(#6599FE, 10%);
    }
  }
</style>

<template>
  <div class="media-preview">
    <div class="row">
      <div class="col-lg-12">
        <img
          :src="youtubeThumbnailPath"
          :title="title"
          width="100%"
          height="auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeThumbnail',
  props: {
    youtubeId: {
      type: String
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    youtubeThumbnailPath () {
      return 'https://i.ytimg.com/vi/' + this.youtubeId + '/default.jpg'
    }
  }
}
</script>

<style lang="scss">
.media-preview {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  display: block;

  img {
    float: left;
    margin-right: 10px;
  }
}
</style>
